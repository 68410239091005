/**
 * BF Styles
 */

/* Global */
body {
  color: var(--bf-color-black);
  font-size: var(--bf-font-size-base);
  line-height: var(--bf-line-height-base);
  font-family: var(--bf-font-family-brand), var(--bf-font-family-fallback);
}

a {
  color: var(--bf-color-brand-primary);
}

a:hover {
  text-decoration: none;
}

img {
  width: 100%;
  height: auto;
}

h1,
h2,
h3,
h4,
h5 {
  text-transform: uppercase;
  color: var(--bf-color-brand-primary);
  line-height: var(--bf-line-height-header);
  margin: var(--bf-spacing-xxl) 0 var(--bf-spacing-xl);
}

h1 {
  font-size: var(--bf-header-1);
  margin-bottom: var(--bf-spacing-xxl);
}

h2 {
  font-size: var(--bf-header-2);
}

h3 {
  font-size: var(--bf-header-3);
}

h4 {
  font-size: var(--bf-header-4);
}

h5 {
  font-size: var(--bf-header-5);
}

@media only screen and (max-width: 992px) {
  html,
  body {
    font-size: var(--bf-font-size-small);
  }
}

/* Buttons */
/* Regular Button */
.bf-btn,
.bf-btn-regular {
  border: 0;
  font-size: 100%;
  cursor: pointer;
  font-family: inherit;
  color: var(--bf-color-white);
  font-weight: var(--bf-font-weight-normal);
  background-color: var(--bf-color-brand-primary);
  padding: var(--bf-spacing-md) var(--bf-spacing-lg);
}

.bf-btn:hover,
.bf-btn-regular:hover {
  opacity: 0.9;
}

.bf-btn:active,
.bf-btn-regular:active {
  opacity: 1;
}

/* Bordered Button */
.bf-btn-bordered {
  background-color: transparent;
  color: var(--bf-color-brand-primary);
  border: 2px solid var(--bf-color-brand-primary);
}

.bf-btn-bordered:hover {
  opacity: 0.7;
}

.bf-btn-bordered:active {
  opacity: 1;
}

/* Buttons Modifiers */
.bf-btn_ghost {
  text-decoration: underline;
  background-color: transparent;
  color: var(--bf-color-brand-primary);
}

.bf-btn_ghost:hover {
  text-decoration: none;
}

.bf-btn_uppercase {
  text-transform: uppercase;
}

.bf-btn_inverted {
  color: var(--bf-color-brand-primary);
  background-color: var(--bf-color-white);
}

/* Logo */
/* Regular Logo */
.bf-logo {
  line-height: 1.1;
  display: inline-block;
  text-transform: uppercase;
  font-size: var(--bf-header-3);
  color: var(--bf-color-brand-primary);
}

.bf-logo-finland {
  font-weight: var(--bf-font-weight-bold);
}

/* Secondary Logo */
.bf-logo_secondary {
  color: var(--bf-color-white);
}

/* Medium Logo */
.bf-logo_md {
  font-size: var(--bf-header-4);
}

/* Small Logo */
.bf-logo_sm {
  font-size: var(--bf-header-5);
}

/* Tag */
.bf-tag {
  cursor: pointer;
  display: inline-block;
  text-transform: uppercase;
  font-size: var(--bf-font-size-small);
  color: var(--bf-color-brand-primary);
  border-radius: var(--bf-border-radius-bubble);
  border: 1px solid var(--bf-color-brand-primary);
  padding: var(--bf-spacing-sm) var(--bf-spacing-lg);
}

.bf-tag_active {
  color: var(--bf-color-white);
  background-color: var(--bf-color-brand-primary);
}

.bf-tag_simple {
  padding: 0;
  border: none;
  text-decoration: underline;
  font-size: var(--bf-font-size-small);
}

.bf-tag_simple:hover {
  text-decoration: none;
}

/* Link */
.bf-link {
  cursor: pointer;
  text-decoration: underline;
  color: var(--bf-color-brand-primary);
}

.bf-link:hover {
  text-decoration: none;
}

.bf-link_white {
  color: var(--bf-color-white);
}

/* Tab */
.bf-tab {
  cursor: pointer;
  display: inline-block;
  text-transform: uppercase;
  padding: var(--bf-spacing-sm) var(--bf-spacing-sm);
}

.bf-tab_active {
  box-shadow: var(--bf-color-brand-primary) 0px -4px inset;
}

/* Breadcrumbs */
.bf-breadcrumbs {
  display: flex;
  flex-wrap: wrap;
  font-size: var(--bf-font-size-small);
}

.bf-breadcrumbs .bf-link:after {
  content: '»';
  text-decoration: none;
  display: inline-block;
  padding-left: var(--bf-spacing-xs);
  margin-right: var(--bf-spacing-xs);
}

/* Locale */
.bf-locale {
  cursor: pointer;
  display: inline-block;
  padding: var(--bf-spacing-xs);
}

.bf-locale_active {
  color: var(--bf-color-white);
  background-color: var(--bf-color-brand-primary);
}

/* Divider */
.bf-divider {
  height: 2px;
  border: none;
  background-color: var(--bf-color-lightgray);
}

/* Form Label */
.bf-label {
  display: block;
}

/* Form Textarea & Input */
.bf-textarea {
  resize: none;
  min-height: 100px;
}

.bf-textarea,
.bf-input {
  width: 100%;
  font-size: inherit;
  font-family: inherit;
  padding: var(--bf-spacing-sm);
  border: 1px solid var(--bf-color-gray);
  border-radius: var(--bf-border-radius-input);
}

.bf-input-icon {
  height: auto;
  cursor: pointer;
  width: 1.6rem;
  right: var(--bf-spacing-sm);

  top: 50%;
  position: absolute;
  transform: translateY(-50%);
}

.bf-input-container {
  position: relative;
}

.bf-input-container .bf-input {
  padding-right: var(--bf-spacing-xl);
}

.bf-textarea::placeholder,
.bf-input::placeholder {
  color: var(--bf-color-gray);
}

/* Border List */
.bf-border-list .bf-link {
  display: block;
  padding: var(--bf-spacing-lg) 0;

  text-decoration: none;
  text-transform: uppercase;
  border-top: 1px solid var(--bf-color-gray);
}

.bf-border-list .bf-link:hover {
  text-decoration: underline;
}

.bf-border-list .bf-link:last-child {
  padding-bottom: 0;
}

/* Unorder List & List Items */
.bf-ul,
.bf-li {
  margin: 0;
  padding: 0;
}

.bf-ul {
  margin: var(--bf-spacing-xl) 0 var(--bf-spacing-xl) 20px;
}

.bf-li {
  margin: var(--bf-spacing-md) 0;
}

/* CTA Box */
.bf-cta-box {
  padding: var(--bf-spacing-xl);
  font-size: var(--bf-font-size-small);
  background-color: var(--bf-color-lightgray);
}

.bf-cta-box-header {
  text-transform: uppercase;
  font-size: var(--bf-header-4);
  color: var(--bf-color-brand-primary);
  font-weight: var(--bf-font-weight-bold);
}

.bf-cta-box-list {
  margin: var(--bf-spacing-xl) 0;
}

.bf-cta-box-button {
  width: 100%;
}

/* Form */
.bf-form-title {
  padding: 0;
  text-transform: uppercase;
  font-size: var(--bf-header-4);
  color: var(--bf-color-brand-primary);
  font-weight: var(--bf-font-weight-bold);
  margin-bottom: var(--bf-spacing-md);
}

.bf-form .bf-form-input {
  margin-bottom: var(--bf-spacing-lg);
}

.bf-form-btn {
  display: block;
}

/* Hero */
.bf-hero {
  position: relative;
  height: 500px;
}

.bf-hero-content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  z-index: 1;
}

.bf-hero-title {
  margin: 0;
  color: var(--bf-color-white);
}

.bf-hero-subtitle {
  margin: var(--bf-spacing-lg) 0;
  font-size: var(--bf-header-4);
  color: var(--bf-color-white);
  text-transform: uppercase;
}

.bf-hero-cover {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  opacity: 0.2;
  background-color: var(--bf-color-black);
}

.bf-hero-image img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

/* Checkbox */
.bf-checkbox {
  display: inline-flex;
  align-items: center;
}

.bf-checkbox-label {
  padding: var(--bf-spacing-sm) var(--bf-spacing-xs);
  line-height: 1;
}

/* Cookie */
.bf-cookie {
  color: var(--bf-color-white);
  padding: var(--bf-spacing-lg);
  background-color: var(--bf-color-brand-primary);
}

.bf-cookie .bf-link {
  color: var(--bf-color-white);
}

.bf-cookie-checkboxes {
  display: flex;
  flex-wrap: wrap;
  margin: var(--bf-spacing-md) 0;
}

.bf-cookie-checkboxes .bf-checkbox {
  margin-right: var(--bf-spacing-md);
}

/* Header */
.bf-header {
  display: grid;
  height: 100px;
  width: 100%;
  grid-template-columns: 100px auto 100px;
}

.bf-header-body {
  border-right: 1px solid var(--bf-color-lightgray);
  border-left: 1px solid var(--bf-color-lightgray);
  padding: 0 var(--bf-spacing-xl);
}

.bf-header-body,
.bf-header-menu,
.bf-header-search {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.bf-header-menu,
.bf-header-search {
  justify-content: center;
}

.bf-header-icon {
  width: 34px;
  height: auto;
  cursor: pointer;
}

.bf-header-body-left {
  display: flex;
}

.bf-header-body-right {
  display: flex;
  align-items: center;
  align-self: flex-start;
  font-size: var(--bf-font-size-small);
}

.bf-header-title {
  line-height: 1.4;
  align-self: flex-end;
  font-size: var(--bf-header-5);
  margin-left: var(--bf-spacing-xl);
  color: var(--bf-color-brand-primary);
}

.bf-header-body-links {
  padding-right: var(--bf-spacing-sm);
}

.bf-header-body-links .bf-link {
  padding-right: var(--bf-spacing-sm);
}

/* Media queries */
@media only screen and (max-width: 992px) {
  .bf-header-body-right {
    display: none;
  }
}

@media only screen and (max-width: 768px) {
  .bf-header-title {
    display: none;
  }
}

/* Custom Fonts */
@font-face {
  font-family: 'Finlandica';
  src: url('../fonts/finlandica-bold.woff2') format('woff2'),
    url('../fonts/finlandica-bold.woff') format('woff');

  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Finlandica';
  src: url('../fonts/finlandica-bolditalic.woff2') format('woff2'),
    url('../fonts/finlandica-bolditalic.woff') format('woff');

  font-weight: 700;
  font-style: italic;
}

@font-face {
  font-family: 'Finlandica';
  src: url('../fonts/finlandica-italic.woff2') format('woff2'),
    url('../fonts/finlandica-italic.woff') format('woff');

  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: 'Finlandica';
  src: url('../fonts/finlandica-regular.woff2') format('woff2'),
    url('../fonts/finlandica-regular.woff') format('woff');

  font-weight: 400;
  font-style: normal;
}