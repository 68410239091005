/**
 * BF SSO Styles
 */

/** CSS modules */
@import "./assets/styles/reset.css";
@import "./assets/styles/tokens.css";
@import "./assets/styles/styles.css";

/**
 * Overrides
 */

h1, h2, h3, h4, h5, h6 {
  font-size: unset;
  text-transform: unset;
  margin: unset;
  line-height: unset;
  font-weight: var(--bf-font-weight-bold);
}

a {
  text-decoration: underline;
}

@media only screen and (max-width: 992px) {
  html,
  body {
    font-size: var(--bf-font-size-base);
  }
}

/**
 * Custom styles
 */

html, body, #root {
  height: 100%;
}

.hidden {
  display: none;
}

.text-center {
  text-align: center;
}

.flex {
  display: flex;
}

.space-between {
  justify-content: space-between;
}